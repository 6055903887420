import React, {useContext} from "react"
import loadable from '@loadable/component'

import { CurrentSelectedAddress } from "../../globalstates"
import { HomemadeShopList, HomePageCurrentState } from "./state/HomePageState"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Container from "@material-ui/core/Container"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import Loaders from "./component/StaggeredCardsPreloader"
import "./HomePage.css"




import { navigate } from "gatsby"
import useBag from "../BagPage/custom_hooks/useBag"
// import BagButton from "../../Buttons/BagButton"


const ShopsList = loadable(() => import('./ShopsList'))


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
  },
}))

const HomePage = () => {
  const HomePageStates = Object.freeze({
    loading: 1,
    loaded: 2,
    error: 3,
  })

  const classes = useStyles()

  // eslint-disable-next-line no-unused-vars
  const [currentSelectedAddress] = useContext(
    CurrentSelectedAddress
  )

  const [shops, setShops] = useContext(HomemadeShopList)

  const [pageState, setPageState] = useContext(HomePageCurrentState)

  const { BagButton } = useBag()

  function state(currentState) {

    switch (currentState) {
      case HomePageStates.loading:
        return <Loaders />
      case HomePageStates.loaded:
        return <ShopsList shops={shops} />

      default:
        return <div>Something went wrong...</div>
    }
  }

  return (
    <div>
      <AppBar color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => {
              navigate("/")
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography className={classes.title}>
            <div className="deliverTo">DELIVER TO</div>
            <div
              aria-hidden="true"
              className="addressText"
              onClick={() => {
                navigate("/map")
              }}
              onKeyDown={() => {
                navigate("/map")
              }}
            >
              {currentSelectedAddress.address.substring(0, 70)}
              {currentSelectedAddress.address.length >= 70 ? "..." : ""}
            </div>
          </Typography>
          <BagButton />
        </Toolbar>
      </AppBar>
      <Toolbar />

      <Container maxWidth="xs" disableGutters="true">
        <div>{state(pageState)}</div>

      </Container>
    </div>
  )
}

export default HomePage
