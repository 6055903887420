import React from "react"
import { theme } from "../../assets/mui"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import HomePage from './HomePage/HomePage'

const MainIndexPage = () => {
  return (
    <div className="page">
      <MuiThemeProvider theme={theme}>
        <Container maxWidth="xs" disableGutters="true">
            <HomePage />
        </Container>
      </MuiThemeProvider>
    </div>
  )
}

export default MainIndexPage
