import React, { useState } from "react"

const StaggeredCardImage = ({ className, src, alt, width, height }) => {
  const [loaded, setLoaded] = useState(false)
  let classname = loaded ? `${className}` : `${className} loading`
  return (
    <img
      className={classname}
      width={width}
      height={height}
      loading = 'lazy'
      src={src + "?auto=compress&w=300"}
      alt={alt}
      onLoad={() => {
        setLoaded(true)
      }}
    />
  )
}

export default StaggeredCardImage
