import React from 'react'
import StaggeredCard from './StaggeredCard'

import './StaggeredCard.css'

const styles = {
  small: {
    flex: '34.3%'
  },
  large: {
    flex: '65%'
  },
}

const Card = ({ size = 'small' }) => {
  return (
    <div
      style={{
        margin: "2px 2px",
        padding: 0,
        borderRadius: '1em',
        backgroundColor: 'grey',
        opacity: 0.4,
        ...styles[size],
      }}
    ></div>
  )
}


const Row = () => {

  return <>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
      height: '570px',
      paddingTop: '0.4em',
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        <Card size='large' />
        <Card size='small' />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        <Card size='small' />
        <Card size='large' />
      </div>
    </div >
  </>

}



const Preloader = () => {
  return <div> <Row /> <Row /></div>
}

export default Preloader
